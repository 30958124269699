import React, { useEffect } from 'react';
import { graphql, navigate, PageProps } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { getObjectFromQueryString } from '../utils/objectUtils';
import { RootUrlQueries } from '../const/routes.const';
import RobotsNoIndex from '../components/RobotsNoIndex/RobotsNoIndex';
import SetUserMetaData from '../components/SetUserMetaData/SetUserMetaData';

const RedirectToLanguagePage = (props: PageProps): JSX.Element => {
  const { language, changeLanguage, languages, defaultLanguage } = useI18next();
  const { location } = props;

  useEffect(() => {
    const queryObj = getObjectFromQueryString(location.search);
    const redirectPath = queryObj[RootUrlQueries.RedirectPath];
    const redirectLang =
      queryObj[RootUrlQueries.Language] && languages.includes(queryObj[RootUrlQueries.Language])
        ? queryObj[RootUrlQueries.Language]
        : defaultLanguage;

    if (redirectPath) {
      if (redirectLang !== language) {
        changeLanguage(redirectLang);
      }

      navigate(`${redirectLang !== defaultLanguage ? `/${redirectLang}` : ''}${redirectPath}`);
    } else {
      navigate('/');
    }
  }, []);

  return <SetUserMetaData />;
};

export default RedirectToLanguagePage;

export function Head() {
  return <RobotsNoIndex />;
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["translation"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
